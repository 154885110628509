<template>
  <router-view></router-view>
</template>
<script>
import { clearAppData } from '@/mixins/storageUtils.js';

export default {
  name: 'Login',
  beforeRouteEnter(to, from, next) {
    next(() => {
      if (from && from.name) {
        if (from.name !== 'app-info') {
          clearAppData('login');
        }
      }
    });
  },
};
</script>
